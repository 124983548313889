import type { RouteRecordRaw } from 'vue-router';
import { overview } from '@/modules/statistics/ui/router/names';
import { guard } from '@/common/router/public';
import { StatisticsMenu } from '@/modules/statistics/domain/policies';

export const statisticsRouter: RouteRecordRaw = {
  path: 'statistics',
  name: 'statistics',
  component: () => import(/* webpackChunkName: "statistics" */ '../pages/TheStatistics.vue'),
  beforeEnter: guard().hasAuthorization(StatisticsMenu.AccessStatisticsPage),
  redirect: {
    name: 'overview',
  },
  children: [
    {
      path: 'overview',
      name: overview,
      component: () =>
        import(
          /* webpackChunkName: "statistics" */ '../components/omni-statistics/OmniStatisticsOverview.vue'
        ),
    },
    {
      path: 'traffic',
      name: 'traffic',
      beforeEnter: guard().hasAuthorization(StatisticsMenu.AccessStatisticsTrafficPage),
      component: () =>
        import(/* webpackChunkName: "statistics" */ '../pages/TrafficStatistics.vue'),
    },
    {
      path: 'channels',
      name: 'channels',
      beforeEnter: guard().hasAuthorization(StatisticsMenu.AccessStatisticsChannelsPage),
      component: () =>
        import(/* webpackChunkName: "statistics" */ '../pages/ChannelsStatistics.vue'),
    },
    {
      path: 'team',
      name: 'team',
      beforeEnter: guard().hasAuthorization(StatisticsMenu.AccessStatisticsTeamPage),
      component: () => import(/* webpackChunkName: "statistics" */ '../pages/TeamStatistics.vue'),
    },
    {
      path: 'status',
      name: 'status',
      beforeEnter: guard().hasAuthorization(StatisticsMenu.AccessStatisticsSubjectsPage),
      component: () =>
        import(/* webpackChunkName: "statistics" */ '../pages/SubjectsStatistics.vue'),
    },
    {
      path: 'confirmation-calls',
      name: 'confirmationCalls',
      beforeEnter: guard().hasAuthorization(StatisticsMenu.AccessStatisticVisitConfirmationPage),
      component: () =>
        import(/* webpackChunkName: "statistics" */ '../pages/ConfirmationCalls.vue'),
    },
    {
      path: 'callbacks',
      name: 'callbacks',
      beforeEnter: guard().hasAuthorization(StatisticsMenu.AccessStatisticsCallbackPage),
      component: () =>
        import(/* webpackChunkName: "statistics" */ '../pages/CallbacksStatistics.vue'),
    },
  ],
};
