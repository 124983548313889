import { injectable } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import type { Policy } from '@/common/authorization/public/types';
import { Authorization } from '@/common/authorization/public/api';
import { GeneralSettingsOptions, SettingMenu } from '@/modules/settings/domain/policies';
import { ComplianceTabGate } from '@/modules/settings/domain/gates/ComplianceTabGate';
import type { PolicyValidator } from '../../domain/PolicyValidator';
import { PolicyValidatorToken } from '../token';
import { AuthorizationPolicyValidator } from '../../adapters/AuthorizationPolicyValidator';
import { RootStoreToken } from '@/common/root';

@injectable()
export class AuthorizationProvider implements DpProvider {
  constructor(
    private readonly authorization: Authorization,
    private readonly complianceTabGate: ComplianceTabGate,
  ) {}

  register(container: DependencyContainer): void {
    container.register<PolicyValidator>(PolicyValidatorToken, AuthorizationPolicyValidator);
  }

  boot(container: DependencyContainer): void {
    this.authorization.addPolicies(this.getPolicies(container));
  }

  private hasWhatsAppChannel(container: DependencyContainer): boolean {
    const store: any = container.resolve(RootStoreToken);
    const { channels } = store.getters.getUserFacility;
    return channels.has_text_channel === true && channels.type === 'whatsapp';
  }

  private getPolicies(container: DependencyContainer): Policy[] {
    return [
      {
        name: SettingMenu.AccessGeneralSettings,
        handler: user => user.isManager(),
      },
      {
        name: SettingMenu.AccessMessageTemplates,
        handler: () => this.hasWhatsAppChannel(container),
      },
      {
        name: SettingMenu.AccessWorkingHoursSettings,
        handler: () => true,
      },
      {
        name: SettingMenu.AccessSubjectsSettings,
        handler: user => user.hasFeature('SUBJECT_SETTINGS'),
      },
      {
        name: SettingMenu.AccessChannelsSettings,
        handler: user => user.isManager(),
      },
      {
        name: SettingMenu.AccessWorkstationsSettings,
        handler: user => user.isManager(),
      },
      {
        name: SettingMenu.AccessNonReceptionWorkstationsSettings,
        handler: user => user.isManager() && user.hasFeature('WORKSTATION_NON_RECEPTION'),
      },
      {
        name: SettingMenu.AccessVoiceIntrosSettings,
        handler: () => true,
      },
      {
        name: SettingMenu.AccessTrafficControl,
        handler: user => user.isManager() && user.hasFeature('TRAFFIC_CONTROL'),
      },
      {
        name: SettingMenu.AccessPermissionSettings,
        handler: user => user.isManager(),
      },
      {
        name: SettingMenu.AccessContactList,
        handler: () => true,
      },
      {
        name: SettingMenu.AccessComplianceSettings,
        handler: user => user.isManager() && this.complianceTabGate.requiredRegulatory(),
      },
      {
        name: SettingMenu.AccessDataExport,
        handler: user => user.isManager(),
      },
      {
        name: GeneralSettingsOptions.AccessTasksAutomation,
        handler: user => user.hasFeature('AI_SUMMARIES'),
      },
    ];
  }
}
