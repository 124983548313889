/** compose functions with side effects */
export const each =
  (...functions) =>
  (...args) =>
    functions.forEach(fn => fn(...args));

/** compos pure functions from left to right */
export const pipe =
  (...fns) =>
  arg =>
    fns.reduce((args, fn) => fn(args), arg);
export const compose = (...fns) => pipe(...[...fns].reverse());
export const pipeValue = (value, ...fns) => pipe(...fns)(value);
export const composeValue = (...args) => pipeValue(...[...args].reverse());

export const identity = item => item;
export const constant = item => () => item;
export const not =
  fn =>
  (...args) =>
    !fn(...args);
export const prop = (...keys) => pipe(...keys.map(key => obj => obj[key]));
export const equal = expected => value => expected === value;
export const toProp = key => value => ({ [key]: value });
export const renameProp = (from, to) => pipe(prop(from), toProp(to));
export const nth = i => list => list[i];
export const head = nth(0);
export const tail = list => list.slice(1);
export const map = fn => data => data.map(fn);
export const filter = fn => data => data.filter(fn);
export const reduce = (fn, init) => data => data.reduce(fn, init);
export const conditional = (fn, ifTrue, ifFalse) => any => (fn(any) ? ifTrue(any) : ifFalse(any));
export const filterBy = fn => list => value => list.filter(item => fn(item) === value);
export const sort = fn => data => data.sort(fn);
export const withEntries = fn => obj => compose(Object.fromEntries, fn, Object.entries)(obj);
export const mapEntryItem = fn => map(({ 0: key, 1: item }) => [key, fn(item)]);
export const mapObject = fn => compose(withEntries, mapEntryItem)(fn);
export const extendObject = fn => obj => ({ ...obj, ...fn(obj) });
export const derriveKey = fn => map(pipe(tail, head, item => [fn(item), item]));
export const toArray = fn => obj => Object.values(fn(obj));
export const indexBy = fn => compose(withEntries, derriveKey)(fn);
export const sortLexicallyBy = fn => sort((alpha, bravo) => fn(alpha).localeCompare(fn(bravo)));
export const filterKeys = fn => compose(withEntries, filter)(pipe(head, key => fn(key)));
export const take = (...keys) => filterKeys(key => keys.includes(key));
export const leave = (...keys) => filterKeys(not(key => keys.includes(key)));
export const chunk = n => arr => (arr.length ? [arr.slice(0, n), ...chunk(n)(arr.slice(n))] : []);
export const uniqueByKey =
  keyName =>
  ({ [keyName]: value }, i, fullArray) =>
    i === fullArray.findIndex(document => document[keyName] === value);
export const startsWith = prefix => s => s.startsWith(prefix);
export const isIncludedIn = arr => item => arr.includes(item);
export const some = fn => arr => arr.some(fn);
export const every = fn => arr => arr.every(fn);
export const slice = (start, end) => str => str.slice(start, end);
export const replace = (from, to) => string => string.replace(from, to);
export const trim = string => string.trim();
export const toLowerCase = string => string.toLowerCase();
export const slugify = compose(
  replace(/^-+|-+$/g, ''),
  replace(/[\s_-]+/g, '-'),
  replace(/[^\w\s-]/g, ''),
  trim,
  toLowerCase,
);

export const unpack =
  keyName =>
  ({ [keyName]: unpackedValue, ...rest }) => ({ ...rest, ...unpackedValue });

export const weightedAverage =
  (key, divisor = 'count') =>
  (acc, entry) => {
    if (!entry) {
      return acc;
    }

    const count = acc[divisor] + entry[divisor];

    return {
      [key]: (acc[key] * acc[divisor] + entry[key] * entry[divisor]) / count,
      [divisor]: count,
    };
  };

/** ensure the actual function is called only once until the invalidator changes */
export function promiseOnce(fn, invalidator) {
  let cachedPromise;
  let currentValue;

  return () => {
    if (currentValue === invalidator() && cachedPromise) {
      return cachedPromise;
    }

    cachedPromise = new Promise(resolve => fn().then(resolve));
    currentValue = invalidator();

    return cachedPromise;
  };
}

export const path = (pathArr, obj) =>
  pathArr.reduce((currentObj, currentPath) => currentObj[currentPath], obj);

export const sortByWeight = (a, b) => {
  if (typeof a.weight !== 'number' || typeof b.weight !== 'number') {
    throw new Error('Sorted element doesn\'t have "weight" property.');
  }

  return a.weight - b.weight;
};

export const isTruthy =
  (success, failure = null) =>
  value =>
    value ? success : failure;

// This is a fake t() function, just to help find translation keys.
export const $t = identity;
export const truthy = identity;
