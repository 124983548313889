<template>
  <div :class="$b()">
    <slot name="default" />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';

@Component({
  name: 'CustomPropertiesProvider',
})
export default class CustomPropertiesProvider extends Vue {}
</script>
<style lang="scss">
@use '@/assets/scss/variables' as v;
@use 'sass:color';

:root {
  --brand-primary: #{v.$primary};
  --brand-primary-semitransparent: #{color.change(v.$primary, $alpha: 0.5)};
  --brand-warning: #{v.$warning};
  --brand-warning-semitransparent: #{color.change(v.$warning, $alpha: 0.5)};
  --brand-success: #{v.$secondary};
  --brand-success-semitransparent: #{color.change(v.$secondary-dark, $alpha: 0.5)};
  --brand-calm: #{v.$calm};
  --brand-danger: #{v.$danger};
  --brand-danger-semitransparent: #{color.change(v.$danger, $alpha: 0.5)};
  --brand-gray-100: #{v.$gray-100};
  --brand-gray-200: #{v.$gray-200};
  --brand-gray-300: #{v.$gray-300};
  --brand-gray-500: #{v.$gray-500};
  --brand-gray-600: #{v.$gray-600};
  --brand-gray-900: #{v.$gray-900};
  --brand-white: #{v.$white};
  --phone-navbar-simple-height: #{v.$navbar-simple-height};
}
</style>
