import { inject, injectable } from 'tsyringe';
import { Task } from '@/modules/task/domain/task/Task';
import type { StoreTask } from '@/modules/task/adapters/task/StoreTask';
import { VoiceMenu } from '@/modules/task/domain/voice-menu/VoiceMenu';
import { Caller } from '../../domain/task/Caller';
import { OwnerIdFactory } from '../owner/OwnerIdFactory';
import { Voicemail } from '../../domain/voicemail/Voicemail';
import type { Patient } from '@/modules/task/domain/patient/Patient';
import type { PatientRepository } from '@/modules/task/domain/patient/PatientRepository';
import { ChannelRepositoryToken, PatientRepositoryToken } from '@/modules/task/di/tokens';
import type { Channel } from '@/modules/task/domain/channel/Channel';
import type { ChannelRepository } from '@/modules/task/domain/channel/ChannelRepository';

@injectable()
export class TaskFactory {
  constructor(
    private readonly ownerIdFactory: OwnerIdFactory,
    @inject(PatientRepositoryToken)
    private readonly patientRepository: PatientRepository,
    @inject(ChannelRepositoryToken)
    private readonly channelRepository: ChannelRepository,
  ) {}

  make(task: StoreTask): Task {
    return new Task(
      task.id,
      task.type,
      task.status,
      task.tab,
      this.ownerIdFactory.tryMake(task.personnel),
      task.patientId,
      this.makeVoiceMenu(task.voice_menu),
      task.comment,
      task.anonymous,
      this.hasPriority(task),
      new Caller(task.caller),
      task.expires_at,
      task.created_at,
      this.makeVoicemail(task.voicemail),
      task.result,
    );
  }

  makeEmptyToDo(): Task {
    return new Task(
      Task.newToDoId,
      'outbound-call',
      'open',
      'todo',
      this.ownerIdFactory.tryMake(),
      '',
      VoiceMenu.makeEmpty(),
      '',
      false,
      false,
      new Caller(''),
      '',
      '',
      this.makeVoicemail({ sent: false, converted: false }),
    );
  }

  private makeVoiceMenu({
    label,
    priority,
  }: {
    label?: string;
    priority?: number;
  } = {}): VoiceMenu {
    return VoiceMenu.make(label ?? '', !!priority);
  }

  private makeVoicemail(voicemail: StoreTask['voicemail']): Voicemail {
    return new Voicemail(voicemail.sent, voicemail.converted);
  }

  private hasPriority({ patientId, voice_menu, phone }: StoreTask): boolean {
    return (
      this.getPatient(patientId).priority ||
      this.getChannel(phone.id).hasPriority ||
      !!voice_menu?.priority
    );
  }

  private getPatient(patientId: string): Patient {
    return this.patientRepository.get(patientId);
  }

  private getChannel(channelId: string): Channel {
    return this.channelRepository.get(channelId);
  }
}
